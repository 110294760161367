import { SequenceDocument } from '../../../core/sequence-viewer/sequence-selection.service';
import {
  GraphControlTypeEnum,
  GraphDatasource,
  GraphDatasourceError,
  GraphSidebarControls,
  GraphSidebarDatasourceResponse,
  GraphTypeEnum,
  TreeGraphBranchTransform,
  TreeGraphData,
  TreeGraphOptions,
} from '../graph-sidebar';
// @ts-ignore
import { parse_newick } from '@geneious/biojs-io-newick';
import { ColorPaletteID } from 'src/app/core/color/color-palette.model';
import { heavyGenes, lightGenes } from '../../../core/antibodyAnnotatorRegions.service';
import { SelectGroup, SelectOption } from '../../../core/models/ui/select-option.model';
import { MetadataColumnAbomination } from '../../sequence-viewer-angular/sequence-viewer.interfaces';
import {
  TreeGraphColoringHeatmapConfig,
  TreeGraphColoringMetadataConfig,
  TreeGraphMetadataMap,
} from '../graph-circular-tree/graph-circular-tree.model';
import {
  CircularTreeHeatmapConfigComponent,
  CircularTreeHeatmapConfigOptions,
} from '../graph-circular-tree/circular-tree-heatmap-config/circular-tree-heatmap-config.component';
import { Component, Injector } from '@angular/core';
import { GraphCircularTreeComponent } from '../graph-circular-tree/graph-circular-tree.component';
import { TreeMetadataExtractor } from './tree-metadata-extractor';
import { of } from 'rxjs';

export class TreeGraphDatasource implements GraphDatasource {
  private readonly METADATA_PREFIX_PATTERN = /^(BX_)?(AGGREGATE_)?(CHERRY_PICKING_)?(ASSAY_DATA_)?/;

  tree: TreeGraphData;
  flatTree: TreeGraphData[];
  tipLabels: Map<string, string> = new Map();
  tipLabelsFieldName: string;
  coloringMetadataValues: TreeGraphMetadataMap = new Map();
  supportedGeneFamiliesForColoring: MetadataColumnAbomination[] = [];
  selectedMetadataForColoring: MetadataColumnAbomination[] = [];
  otherMetadataForColoring: MetadataColumnAbomination[] = [];
  allSupportedMetadataForColoring: MetadataColumnAbomination[] = [];
  coloringMetadataConfig: TreeGraphColoringMetadataConfig;
  treeMetadataExtractor: TreeMetadataExtractor;
  initialOptions: TreeGraphSidebarOptions;
  savedOptions: Partial<TreeGraphSidebarOptions> = {};
  constructor(
    private document: SequenceDocument,
    private sortedMetadataColumns: MetadataColumnAbomination[],
    private metadataColumnSelection: { [k: string]: unknown },
    private documentId: string,
  ) {
    this.initializeSupportedMetadataColumnsForColoring();
  }

  getIdForSavedOptions$() {
    return of({ id: `${this.documentId}-circular-tree` });
  }

  validate(): GraphDatasourceError | null {
    return null;
  }

  setInitialOptions(initialOptions: Record<string, any>) {
    this.savedOptions = initialOptions as TreeGraphSidebarOptions;
  }

  init(): Promise<GraphSidebarDatasourceResponse> {
    const validation = this.validate();
    if (validation) {
      return Promise.resolve(validation);
    }

    let defaultTipLabels = 'sequenceName';

    const isCollapseSequenceOptionEnabled = this.document.sequences.some(
      (sequence) => 'BX_Total combined sequences' in sequence.metadata,
    );

    if (isCollapseSequenceOptionEnabled) {
      defaultTipLabels = 'BX_Representative sequence';
    } else if (this.sortedMetadataColumns.some((column) => column.name === 'BX_Name')) {
      defaultTipLabels = 'BX_Name';
    }

    const options: TreeGraphSidebarOptions = {
      tipLabels: defaultTipLabels,
      branchTransform: 'cladogram',
      showTipLabels: true,
      showHeatmap: true,
      showLegend: this.allSupportedMetadataForColoring.length > 0,
      maxTipLabelChars: 15,
      autoColorBranches: this.allSupportedMetadataForColoring.length > 0,
      coloringMetadataKey: this.allSupportedMetadataForColoring[0]?.name,
      heatmapConfigs: [],
      colorPalette: 'plasma',
      selectedLegendMetadataField: this.allSupportedMetadataForColoring[0]?.name,
      ...this.savedOptions,
    };
    return this.getData(undefined, options);
  }

  controlValueChanged(
    previousOptions: TreeGraphSidebarOptions | undefined,
    options: TreeGraphSidebarOptions,
  ): Promise<GraphSidebarDatasourceResponse> {
    return this.getData(previousOptions, options);
  }

  private getData(
    previousOptions: TreeGraphSidebarOptions | undefined,
    options: TreeGraphSidebarOptions,
  ): Promise<GraphSidebarDatasourceResponse> {
    if (!this.tree) {
      if (!this.document.trees || this.document.trees.length === 0) {
        return Promise.resolve({
          error: 'No tree data present in this document.',
          controls: [],
        });
      }
      const rawData = parse_newick(this.document.trees[0]) as RawNewickData;
      this.tree = TreeGraphDatasource.formatTreeData(rawData);
      this.flatTree = this.flattenTree(this.tree);
      this.treeMetadataExtractor = new TreeMetadataExtractor(
        this.document,
        this.sortedMetadataColumns,
        this.flatTree,
      );
    }

    if (!previousOptions || previousOptions.tipLabels !== options.tipLabels) {
      const labels = new Map();

      let tipLabelsKey = options.tipLabels;
      if (!this.sortedMetadataColumns.some((column) => column.name === tipLabelsKey)) {
        tipLabelsKey = 'sequenceName';
        this.tipLabelsFieldName = 'Sequence Name';
      } else {
        this.tipLabelsFieldName = tipLabelsKey.replace(this.METADATA_PREFIX_PATTERN, '');
      }

      this.flatTree.forEach((node) => {
        if (!node.id) {
          return;
        }
        labels.set(node.id, this.getLabels(node, tipLabelsKey));
      });
      this.tipLabels = labels;
    }

    if (!previousOptions || previousOptions.coloringMetadataKey !== options.coloringMetadataKey) {
      this.coloringMetadataValues = new Map();
      this.flatTree.forEach((node) => {
        if (!node.id) {
          return;
        }
        this.coloringMetadataValues.set(
          node.id,
          this.treeMetadataExtractor.getMetadataValue(node, options.coloringMetadataKey),
        );
      });
    }

    if (
      !previousOptions ||
      previousOptions.coloringMetadataKey !== options.coloringMetadataKey ||
      previousOptions.colorPalette !== options.colorPalette
    ) {
      this.coloringMetadataConfig = this.treeMetadataExtractor.getColouringMetadataConfig(
        options.coloringMetadataKey,
        options.colorPalette,
        this.coloringMetadataValues,
      );
    }

    if (!options.heatmapConfigs) {
      options.heatmapConfigs = [];
    }

    let selectedShowLegend = options.showLegend;
    if (
      !options.autoColorBranches &&
      (options.heatmapConfigs.length === 0 || !options.showHeatmap)
    ) {
      selectedShowLegend = false;
    }

    for (const config of options.heatmapConfigs) {
      if (Object.entries(config.values).length === 0) {
        // maps cannot be serialised as-is (they are serialised to empty objects), so if the heatmap config
        // comes from saved sidebar options, we need to reconstruct the values map.
        // we could convert the map to an array before serialising,
        // but this would result in the saved options being much larger than necessary
        config.values = this.treeMetadataExtractor.getMetadataValues(config.metadataField);
      }
    }

    let selectedLegendMetadataColoringConfig = Array.from(options.heatmapConfigs.values()).find(
      (option) =>
        option.metadataField === options.selectedLegendMetadataField && options.showHeatmap,
    );

    if (!selectedLegendMetadataColoringConfig) {
      if (options.autoColorBranches) {
        selectedLegendMetadataColoringConfig = { ...this.coloringMetadataConfig, collapsed: false };
      } else {
        selectedLegendMetadataColoringConfig = Array.from(options.heatmapConfigs.values())[0];
      }
    }

    let selectedShowHeatmap = options.showHeatmap;
    if (options.branchTransform !== 'cladogram') {
      selectedShowHeatmap = false;
    }

    const graph: TreeGraphOptions = {
      type: GraphTypeEnum.TREE,
      data: this.tree,
      tipLabelConfig: {
        show: options.showTipLabels,
        maxChars: options.maxTipLabelChars,
        labels: this.tipLabels,
        name: this.tipLabelsFieldName,
      },
      heatmapConfig: {
        show: selectedShowHeatmap,
        heatmapRowConfigs: options.heatmapConfigs,
      },
      legendConfig: {
        show: selectedShowLegend,
        metadataColoringConfig: selectedLegendMetadataColoringConfig,
      },
      branchTransform: options.branchTransform,
      coloringMetadataConfig: this.coloringMetadataConfig,
      autoColorBranches: options.autoColorBranches,
    };
    return Promise.resolve({
      graph,
      controls: this.generateControls(options, graph),
      options: {},
      documentId: this.documentId,
      graphName: 'tree-graph',
    });
  }

  private getLabels(node: TreeGraphData, tipLabelsKey: string): string {
    const sequenceData = this.document.sequences[parseInt(node.id)];
    return tipLabelsKey === 'sequenceName'
      ? sequenceData.sequence.name
      : (sequenceData.metadata[tipLabelsKey] ?? '').toString();
  }

  private initializeSupportedMetadataColumnsForColoring() {
    const allowedGeneFamilies: string[] = [...lightGenes, ...heavyGenes];
    const selectedMetadataColumns = Object.keys(this.metadataColumnSelection);
    this.sortedMetadataColumns.forEach((col) => {
      if (allowedGeneFamilies.includes(col.label)) {
        this.supportedGeneFamiliesForColoring.push(col);
      } else if (
        selectedMetadataColumns.includes(col.name) &&
        this.metadataColumnSelection[col.name]
      ) {
        this.selectedMetadataForColoring.push(col);
      } else {
        this.otherMetadataForColoring.push(col);
      }
    });

    this.allSupportedMetadataForColoring = [
      ...this.supportedGeneFamiliesForColoring,
      ...this.selectedMetadataForColoring,
      ...this.otherMetadataForColoring,
    ];
  }

  private generateControls(
    options: TreeGraphSidebarOptions,
    graphState: TreeGraphOptions,
  ): GraphSidebarControls {
    let selectedTipLabels = options.tipLabels;
    let selectedColoringMetadataKey = options.coloringMetadataKey;
    let selectedAutoColorBranches = options.autoColorBranches;

    if (!this.sortedMetadataColumns.some((column) => column.name === selectedTipLabels)) {
      selectedTipLabels = 'sequenceName';
    }

    if (
      !this.allSupportedMetadataForColoring.some(
        (column) => column.name === selectedColoringMetadataKey,
      )
    ) {
      selectedColoringMetadataKey = undefined;
      selectedAutoColorBranches = false;
    }

    let selectGroups: SelectGroup[] = [];
    if (this.supportedGeneFamiliesForColoring.length > 0) {
      const geneFamilyGroup = this.supportedGeneFamiliesForColoring.map(
        (column) => new SelectOption(column.label, column.name),
      );
      selectGroups.push(new SelectGroup(geneFamilyGroup, 'Gene Families'));
    }
    const remainingMetadataOptions = this.otherMetadataForColoring.map(
      (column) => new SelectOption(column.label, column.name),
    );
    if (this.selectedMetadataForColoring.length > 0) {
      selectGroups.push(
        new SelectGroup(
          this.selectedMetadataForColoring.map(
            (column) => new SelectOption(column.label, column.name),
          ),
          'Displayed columns',
        ),
        new SelectGroup(remainingMetadataOptions, 'Other columns'),
      );
    } else {
      selectGroups.push(new SelectGroup(remainingMetadataOptions, 'Metadata'));
    }
    const coloringDisabled = selectGroups.every((group) => group.isEmpty());
    const coloringTooltip = coloringDisabled
      ? 'Please annotate your sequences or add metadata'
      : undefined;

    const legendDisabled =
      (coloringDisabled || !options.autoColorBranches) &&
      (options.heatmapConfigs.length === 0 || !options.showHeatmap);
    let selectedShowLegend = options.showLegend;
    if (legendDisabled) {
      selectedShowLegend = false;
    }

    let availableLegends: SelectGroup[] = [];
    if (this.coloringMetadataConfig && options.autoColorBranches) {
      availableLegends.push(
        new SelectGroup(
          [
            new SelectOption(
              this.coloringMetadataConfig.name,
              this.coloringMetadataConfig.metadataField,
            ),
          ],
          options.heatmapConfigs.length > 0 ? 'Branch Metadata' : undefined,
        ),
      );
    }

    const uniqueHeatmapConfigs = [
      ...new Map(
        [...options.heatmapConfigs.values()].map((config) => [config.metadataField, config]),
      ).values(),
    ];
    const heatmapLegendOptions = uniqueHeatmapConfigs
      .filter((config) => {
        if (!coloringDisabled && options.autoColorBranches) {
          return config.metadataField !== this.coloringMetadataConfig.metadataField;
        }
        return true;
      })
      .map((config) => new SelectOption(config.name, config.metadataField));
    if (options.showHeatmap && heatmapLegendOptions.length > 0) {
      availableLegends.push(
        new SelectGroup(
          heatmapLegendOptions,
          !coloringDisabled && options.autoColorBranches ? 'Heatmap Metadata' : undefined,
        ),
      );
    }

    return [
      {
        name: 'branchTransform',
        label: 'Branch Transform',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: options.branchTransform,
        options: [
          {
            displayName: 'Cladogram',
            value: 'cladogram',
          },
          {
            displayName: 'Equal',
            value: 'equal',
          },
          {
            displayName: 'No Transform',
            value: 'noTransform',
          },
        ],
        layout: 'block',
      },
      {
        name: 'showTipLabels',
        label: 'Show Tip Labels',
        type: GraphControlTypeEnum.CHECKBOX,
        defaultOption: options.showTipLabels,
        section: 'Labels',
      },
      {
        name: 'tipLabels',
        label: 'Tip Labels',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: selectedTipLabels,
        options: [{ displayName: 'Sequence Name', value: 'sequenceName' }].concat(
          this.sortedMetadataColumns.map((column) => ({
            displayName: column.label,
            value: column.name,
          })),
        ),
        layout: 'block',
        section: 'Labels',
      },
      {
        name: 'maxTipLabelChars',
        label: 'Max Label Length',
        type: GraphControlTypeEnum.INPUT,
        valueType: 'number',
        defaultOption: options.maxTipLabelChars,
        layout: 'block',
        section: 'Labels',
      },
      {
        name: 'showHeatmap',
        label: 'Show Heatmap',
        type: GraphControlTypeEnum.CHECKBOX,
        defaultOption: graphState.heatmapConfig.show,
        disabled: options.branchTransform !== 'cladogram' || coloringDisabled,
        section: 'Heatmaps',
        tooltip:
          options.branchTransform !== 'cladogram'
            ? 'Heatmap is only available with cladogram branch transform'
            : '',
      },
      {
        name: 'heatmapConfigs',
        label: '',
        defaultOption: options.heatmapConfigs,
        type: GraphControlTypeEnum.COMPONENT,
        disabled: options.branchTransform !== 'cladogram' || coloringDisabled,
        layout: 'block',
        section: 'Heatmaps',
        component: CircularTreeHeatmapConfigComponent as Component,
        injector: (form) =>
          Injector.create({
            providers: [
              {
                provide: CircularTreeHeatmapConfigOptions,
                deps: [],
                useValue: new CircularTreeHeatmapConfigOptions(
                  selectGroups,
                  form,
                  GraphCircularTreeComponent.MAX_HEATMAP_ROWS,
                  ['BX_Score', 'BX_Total'],
                ),
              },
              {
                provide: TreeMetadataExtractor,
                deps: [],
                useValue: this.treeMetadataExtractor,
              },
            ],
          }),
      },
      {
        name: 'autoColorBranches',
        label: 'Color Branches',
        type: GraphControlTypeEnum.CHECKBOX,
        defaultOption: selectedAutoColorBranches,
        disabled: coloringDisabled,
        tooltip: coloringTooltip,
        section: 'Branch Coloring',
      },
      {
        name: 'coloringMetadataKey',
        label: 'Color Based On',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: selectedColoringMetadataKey,
        options: selectGroups,
        disabled: coloringDisabled,
        tooltip: coloringTooltip,
        layout: 'block',
        section: 'Branch Coloring',
      },
      {
        name: 'colorPalette',
        label: 'Color Palette',
        type: GraphControlTypeEnum.PALETTE,
        isCategorical: graphState.coloringMetadataConfig.isCategorical,
        numCategories: graphState.coloringMetadataConfig.numGroups,
        defaultOption: options.colorPalette ?? 'plasma',
        disabled: coloringDisabled,
        tooltip: coloringTooltip,
        section: 'Branch Coloring',
      },
      {
        name: 'showLegend',
        label: 'Show Legend',
        type: GraphControlTypeEnum.CHECKBOX,
        defaultOption: selectedShowLegend,
        disabled: legendDisabled,
        section: 'Legend',
      },
      {
        name: 'selectedLegendMetadataField',
        label: 'Legend to Show',
        type: GraphControlTypeEnum.SELECT,
        defaultOption: options.selectedLegendMetadataField,
        options: availableLegends,
        disabled: legendDisabled,
        layout: 'block',
        section: 'Legend',
      },
    ];
  }

  private flattenTree(tree: TreeGraphData): TreeGraphData[] {
    return [tree, ...tree.children.flatMap((child) => this.flattenTree(child))];
  }

  public static formatTreeData(tree: RawNewickData): TreeGraphData {
    return {
      id: tree.name,
      children: (tree.children ?? []).map((child) => TreeGraphDatasource.formatTreeData(child)),
      branch_length: tree.branch_length,
    };
  }
}

export interface RawNewickData {
  name: string;
  children?: RawNewickData[];
  branch_length: number;
}

interface TreeGraphSidebarOptions {
  showTipLabels: boolean;
  showLegend: boolean;
  selectedLegendMetadataField: string;
  showHeatmap: boolean;
  maxTipLabelChars: number;
  tipLabels: string;
  branchTransform: TreeGraphBranchTransform;
  autoColorBranches: boolean;
  coloringMetadataKey: string;
  colorPalette: ColorPaletteID;
  heatmapConfigs: TreeGraphColoringHeatmapConfig[];
}
